<template>
  <div class="header__language">
    <ul>
      <li
        v-for="item in langs"
        :key="item.code"
        :data-selected="item.code === lang"
        :data-value="item.code"
        @click="changeLanguage"
      >
        {{ item.code }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LanguageSelector',
  data() {
    return {
      value: '',
    };
  },
  computed: {
    ...mapGetters(['lang', 'defaultLang', 'langs', 'currentPost']),
  },
  created() {
    this.value = this.lang;
  },
  methods: {
    checkLanguage(val) {
      return this.currentPost.wpml_translations.find(
        (translation) => translation.locale === val,
      );
    },
    changeLanguage(e) {
      if (e.target.dataset.value === this.value) return;
      this.value = e.target.dataset.value;

      let path = this.value === this.defaultLang ? '/' : `/${this.value}`;
      if (
        this.currentPost
        && this.currentPost
        && this.currentPost.wpml_translations
      ) {
        const currentTranslation = this.checkLanguage(this.value);
        if (currentTranslation) {
          path = this.$relativeUrl(currentTranslation.href);
        }
      }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.header__language {
  pointer-events: auto;

  ul {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    flex: 1 1 auto;
    list-style: none;
    padding: 0;
    margin: 0;
    @include mq(m) {
      grid-auto-flow: row;
      grid-auto-rows: 1fr;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1 auto;
      cursor: pointer;

      padding: 2px 5px;
      background: var(--cream);

      font-family: Barlow-Bold, sans-serif;
      font-size: 15px;
      line-height: 15px;
      @include mq(m) {
        font-size: 18px;
      }
      letter-spacing: -0.04em;
      text-transform: uppercase;

      &[data-selected] {
        background: var(--black);
        color: var(--white);
        cursor: default;
      }
    }
  }
}
</style>
