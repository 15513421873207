<template>
  <figure
    :class="[
      'figure',
      themeClass,
      disableRatio ? 'figure--native' : false,
    ]"
    :data-lazy="lazyload ? 'false' : null"
    :style="{
      '--image-w': image.width,
      '--image-h': image.height,
    }"
  >
    <div :class="['image--wrapper', contain ? 'contain' : false]">
      <img
        ref="image"
        :class="['image']"
        :data-src="lazyload ? src : null"
        :src="!lazyload ? src : ''"
        :alt="image.alt"
      >
      <div
        v-if="lazyload"
        class="placeholder"
      />
    </div>

    <figcaption v-if="caption">
      <Richtext :data="{ innerHTML: caption }" />
    </figcaption>
  </figure>
</template>

<script>
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Figure',
  components: {
    Richtext,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    lazyload: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: () => {},
    },
    disableRatio: {
      type: Boolean,
      default: false,
    },
    contain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
      currentImg: 'default',
      src: null,
    };
  },
  computed: {
    settings() {
      return this.currentImg === 'mobile'
        && this.data.settings.mobile?.toggle
        && this.data.value.image_mobile
        ? this.data.settings.mobile
        : this.data.settings;
    },
    image() {
      return this.currentImg === 'mobile'
        && this.data.settings.mobile?.toggle
        && this.data.value.image_mobile
        ? this.data.value.image_mobile
        : this.data.value.image;
    },
    caption() {
      if (this.data.settings.caption) {
        return this.data.value.caption
          ? this.data.value.caption
          : this.image.caption;
      }
      return false;
    },
    themeClass() {
      return this.theme
        ? `figure--${this.theme}`
        : this.settings.theme
          ? `figure--${this.settings.theme}`
          : false;
    },
  },
  mounted() {
    this.currentSrc();
    this.$bus.$on('windowResized', this.currentSrc);

    if (!this.lazyload) {
      this.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off('windowResized', this.currentSrc);
  },
  methods: {
    currentSrc() {
      let src = this.image.sizes.l;
      const { sizes } = this.image;
      const ratio = window.devicePixelRatio >= 2 ? 2 : 1;
      const wrapper = this.$el.offsetWidth;

      const dimension = 'width';
      const max = wrapper * ratio;
      const ranges = {
        s: sizes[`s-${dimension}`],
        m: sizes[`m-${dimension}`],
        l: sizes[`l-${dimension}`],
        xl: sizes[`xl-${dimension}`],
      };

      const sizesRange = Object.keys(ranges).filter(
        (key) => ranges[key] >= max,
      );
      const size = sizesRange.length > 0 ? sizesRange[0] : 'xl';

      this.$nextTick(() => {
        if (this.$mq.isMobile && this.data.settings.mobile?.toggle) {
          this.currentImg = 'mobile';
        } else {
          this.currentImg = 'default';
        }
        src = this.image.sizes[size];

        if (this.$el.dataset.lazy === 'true') {
          this.$refs.image.src = src;
        }

        this.src = src;
      });
    },
    async load() {
      const img = this.$refs.image;

      if (this.src) {
        img.src = this.src;
      }

      if (img && img.decode && img.src) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.figure {
  margin: 0;
  position: relative;
  overflow: hidden;
  width: 100%;

  --width: var(--image-w);
  --height: var(--image-h);

  .image--wrapper {
    position: relative;
    overflow: hidden;
    display: block;
    &.contain {
      max-height: 100%;
      margin: auto;
    }
  }

  &:not(.figure--native) {
    .image--wrapper {
      @include aspect-ratio(var(--width), var(--height));
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: var(--fit, fill);
    object-position: var(--origin, 50% 50%);
  }

  &--native {
    .image {
      position: relative;
      position: initial;
      height: auto;
    }
  }

  &--default {
    /* --width: 16;
      --height: 9;
      --fit: cover;
      --origin: bottom center; */
  }
  &--hero {
    --fit: contain;
    img {
      max-height: 680px;
    }
  }

  &--gallery-w {
  }

  &--gallery-h {
    width: auto;
    height: 100%;

    .image,
    .image--wrapper {
      width: auto;
      height: 100%;
    }
  }

  &--media-content {
    --fit: cover;

    .image--wrapper {
      aspect-ratio: auto;
      height: 100%;
      line-height: 0;

      img {
        position: relative;
        height: 100%;
      }
    }
  }

  &.align--top {
    .image {
      object-position: 50% 0;
    }
  }

  &.align--center {
    .image {
      object-position: 50% 50%;
    }
  }

  &.align--bottom {
    .image {
      object-position: 50% 100%;
    }
  }

  &.figure-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s $ease-custom;
    will-change: opacity;

    background: var(--grey-d);
  }

  .image {
    visibility: hidden;

    &[src] {
      visibility: visible;
    }
  }
  &[data-lazy='true'] {
    .placeholder {
      opacity: 0;
    }
  }
}
</style>
