export default {
  SET_LANG(state, value) {
    state.lang = value;
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TRANSITION(state, value) {
    state.transition = value;
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value;
  },
  SET_ISSCROLLING(state, value) {
    state.isScrolling = value;
  },
};
