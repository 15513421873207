var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['media-content', _vm.gridLayout, _vm.gridReverse]},[_c('div',{class:['media', _vm.data.settings.bg_color, _vm.data.settings.extra ? 'spacer--padding--x--20_40 spacer--padding--y--40' : '']},[(_vm.data.settings.extra)?_c('div',{staticClass:"title typo--subtitle",domProps:{"innerHTML":_vm._s(_vm.data.value.extra.title)}}):_vm._e(),(_vm.data.settings.image_type === 'map')?_c('div',{staticClass:"map-embed"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":_vm.data.value.map_url,"frameborder":"0","loading":"lazy"}})]):_c('Figure',{class:['img', _vm.data.settings.title ? 'col-3' : 'col-6'],attrs:{"data":{
        value: {
          image: _vm.data.settings.extra ? _vm.data.value.extra.image : _vm.data.value.image,
        },
        settings: {},
      },"lazyload":false,"contain":_vm.data.settings.image_type === 'sketch',"disable-ratio":"","theme":"media-content"}}),(_vm.data.settings.extra)?_c('div',{staticClass:"text typo--text-big",domProps:{"innerHTML":_vm._s(_vm.data.value.extra.text)}}):_vm._e()],1),_c('div',{staticClass:"content spacer--padding--x--20_40 spacer--padding--y--40",class:[_vm.data.settings.bg_color]},[_c('div',{staticClass:"title typo--title-big",domProps:{"innerHTML":_vm._s(_vm.madText(_vm.data.value.title))}}),(_vm.data.settings.extra)?_c('Figure',{class:['img', _vm.data.settings.title ? 'col-3' : 'col-6'],attrs:{"data":{
        value: {
          image: _vm.data.value.image,
        },
        settings: {},
      },"lazyload":false,"contain":_vm.data.settings.image_type === 'sketch',"disable-ratio":"","theme":"media-content"}}):_vm._e(),_c('div',{staticClass:"text typo--subtitle",domProps:{"innerHTML":_vm._s(_vm.data.value.content)}}),(_vm.data.settings.cta)?_c('a',{staticClass:"link typo--menu",attrs:{"href":_vm.data.value.cta.url,"target":_vm.data.value.cta.target},domProps:{"innerHTML":_vm._s(_vm.data.value.cta.title)}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }