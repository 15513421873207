<template>
  <div class="event-thumb">
    <div
      class="title typo--menu"
      v-html="data.event.title.rendered"
    />
    <Figure
      v-if="data.event.gds_featured_image"
      :data="{
        value: {
          image: data.event.gds_featured_image,
        },
        settings: {}
      }"
    />
    <div class="content">
      <div
        class="date typo--label spacer--margin--b--15"
        v-html="data.event.acf.date"
      />
      <div
        class="text typo--text-small"
        v-html="data.event.content.rendered"
      />
    </div>
    <div class="links typo--text-small">
      <a
        v-for="link in links"
        :key="link.id"
        :href="link.url"
        :target="link.target"
        class="link"
        v-html="link.title"
      />
    </div>
  </div>
</template>

<script>
import Figure from '@/components/media/figure';

export default {
  name: 'EventThumb',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    links() {
      return this.data.event.acf.links
        .map((item) => item.link)
        .filter((link) => link.url && link.url !== '');
    },
  },
};
</script>

<style lang="scss" scoped>
.event-thumb {
  display: grid;
  grid-template-rows: repeat(4, min-content);
  row-gap: var(--spacer-15);
  scroll-snap-align: start;
  scroll-snap-stop: always;
  padding-right: var(--spacer-20_40);

  &.single {
    @include mq(s) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content 1fr;
      column-gap: var(--spacer-40_80);
      .figure {
        grid-column: 2;
        grid-row-end: span 3;
      }
    }
  }
  .content {
    max-width: 360px;

    .date {
      color: var(--orange);
      text-transform: uppercase;
    }
  }
  .links {
    align-self: end;

    .link {
      display: block;
      width: fit-content;
      margin-top: 6px;
      padding: 2px 12px 4px;
      background: var(--black);
      color: var(--white);
      border: var(--line) solid var(--black);
      text-transform: uppercase;
      text-decoration: none;

      @include mq($and: $hover) {
        &:hover {
          background: none;
          color: var(--black);
        }
      }
    }
  }
  .figure {
    ::v-deep .image--wrapper {
      @include aspect-ratio();

      img {
        object-fit: cover;
      }
    }
  }
}
</style>
