<template>
  <main>
    <Gutenberg
      :data="post.gds_blocks"
    />
    <PageMenu
      v-if="menuBlocks && menuBlocks.length > 0"
      :data="menuBlocks"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import data from '@/mixins/data';
import medusa from '@/assets/js/observer';

import Gutenberg from '@/components/blocks';
import PageMenu from '@/components/ui/page-menu';

export default {
  name: 'Page',
  components: {
    Gutenberg,
    PageMenu,
  },
  mixins: [data],
  data() {
    return {
      intersectionStack: [],
    };
  },
  computed: {
    ...mapGetters(['isScrolling']),
    menuBlocks() {
      return this.post.gds_blocks
        .filter((block) => {
          const label = block.attrs?.data?.settings?.custom_label;
          return typeof label === 'string' && label !== '';
        })
        .map((block) => block.attrs.data.settings.custom_label);
    },
  },
  mounted() {
    const { path } = this.$route;
    const isPageMenu = path.includes('servizi') || path.includes('stanze');
    if (isPageMenu) {
      // if (this.$route.hash) {
      //   this.intersectionStack.push(this.$route.hash.slice(1));
      // }
      this.$nextTick(this.setSectionObserver);
    }
  },
  beforeDestroy() {
    if (medusa.ref && medusa.ref.idList.includes('section-observer')) {
      medusa.ref.removeTarget('section-observer');
    }
  },
  methods: {
    setSectionObserver() {
      medusa.ref.addTarget({
        id: 'section-observer',
        threshold: 0.2,
        nodes: [],
        mode: 'default',
        callback: this.setSectionHighlight,
        autoremove: false,
      });
      const sections = Array.from(this.$el.querySelectorAll('section[id]'));
      medusa.ref.pushToTarget('section-observer', sections);
    },
    setSectionHighlight(section) {
      setTimeout(() => {
        if (section.target.id && !this.isScrolling) {
          if (section.isIntersecting) {
            this.intersectionStack.push(section.target.id);
          } else {
            this.intersectionStack = this.intersectionStack.filter((el) => el !== section.target.id);
          }
          if (this.intersectionStack.length > 0) {
            const hash = `#${this.intersectionStack.slice(-1)}`;
            if (this.$route.hash !== hash) {
              this.$router.replace({ hash });
            }
          } else if (this.$route.hash !== '') {
            this.$router.replace({ hash: '' });
          }
        }
        // else if (this.$route.hash) {
        //   this.intersectionStack = [this.$route.hash.slice(1)];
        // }
      }, 0);
    },
  },
};
</script>
