<template>
  <div :class="['media-content', gridLayout, gridReverse]">
    <div :class="['media', data.settings.bg_color, data.settings.extra ? 'spacer--padding--x--20_40 spacer--padding--y--40' : '']">
      <div
        v-if="data.settings.extra"
        class="title typo--subtitle"
        v-html="data.value.extra.title"
      />
      <div
        v-if="data.settings.image_type === 'map'"
        class="map-embed"
      >
        <iframe
          :src="data.value.map_url"
          frameborder="0"
          style="border:0;"
          loading="lazy"
        />
      </div>
      <Figure
        v-else
        :data="{
          value: {
            image: data.settings.extra ? data.value.extra.image : data.value.image,
          },
          settings: {},
        }"
        :lazyload="false"
        :contain="data.settings.image_type === 'sketch'"
        :class="['img', data.settings.title ? 'col-3' : 'col-6']"
        disable-ratio
        theme="media-content"
      />
      <div
        v-if="data.settings.extra"
        class="text typo--text-big"
        v-html="data.value.extra.text"
      />
    </div>
    <div
      class="content spacer--padding--x--20_40 spacer--padding--y--40"
      :class="[data.settings.bg_color]"
    >
      <div
        class="title typo--title-big"
        v-html="madText(data.value.title)"
      />
      <Figure
        v-if="data.settings.extra"
        :data="{
          value: {
            image: data.value.image,
          },
          settings: {},
        }"
        :lazyload="false"
        :class="['img', data.settings.title ? 'col-3' : 'col-6']"
        :contain="data.settings.image_type === 'sketch'"
        disable-ratio
        theme="media-content"
      />
      <div
        class="text typo--subtitle"
        v-html="data.value.content"
      />
      <a
        v-if="data.settings.cta"
        :href="data.value.cta.url"
        :target="data.value.cta.target"
        class="link typo--menu"
        v-html="data.value.cta.title"
      />
    </div>
  </div>
</template>

<script>
import { madText } from '@/assets/js/utils';
import Figure from '@/components/media/figure';

export default {
  name: 'MediaContent',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
    };
  },
  computed: {
    gridLayout() {
      let layout = '';
      switch (this.data.settings.ratio) {
      case 'r_1x1':
        layout = 'layout--1-1';
        break;
      case 'r_1x2':
        layout = 'layout--1-2';
        break;
      case 'r_2x1':
        layout = 'layout--2-1';
        break;

      default:
        layout = 'layout--1-1';
        break;
      }
      return layout;
    },
    gridReverse() {
      return (this.data.settings.layout_order === 'text_image') ? 'reverse' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.media-content {
  display: grid;
  gap: var(--line);
  background: var(--black);
  @include mq(m) {
    grid-auto-flow: column;
  }

  &.layout {
    &--1-1 {
      @include mq(m) {
        grid-template-columns: 1fr 1fr;
      }
    }
    &--1-2 {
      @include mq(m) {
        grid-template-columns: 1fr 2fr;
      }
    }
    &--2-1 {
      @include mq(m) {
        grid-template-columns: 2fr 1fr;
      }
    }
  }
  // &:not(.reverse) > *:first-child {
  //   border-bottom: var(--line) solid var(--black);

  //   @include mq(m) {
  //     border: none;
  //     border-right: var(--line) solid var(--black);
  //   }
  // }
  &.reverse > *:first-child {
    grid-row-start: 2;
    // border-top: var(--line) solid var(--black);

    @include mq(m) {
      grid-row-start: auto;
      grid-column-start: 2;
      // border: none;
      // border-left: var(--line) solid var(--black);
    }
  }
  .media,
  .content {
    display: grid;
    grid-template-rows: 1fr;
    gap: var(--spacer-20_40);
  }
  .content {
    .text {
      max-width: 400px;
    }
    .link {
      text-decoration: none;
      border: var(--line) solid var(--black);
      width: fit-content;
      padding: 15px 20px 18px;
      @include mq(m) {
        padding: 12px 30px 18px;
      }
      @include mq($and: $hover) {
        @include border-bottom-hover();
      }
    }
  }
  .media {
    ::v-deep .text ul {
      // list-style-position: inside;
      list-style-type: '• \00a0';
      padding-left: 10px;
    }
  }
  .map-embed {
    @include aspect-ratio();
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>>
