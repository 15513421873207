<template>
  <div class="numbers spacer--padding--x--20_40 spacer--padding--y--40_80">
    <div
      v-for="(el) in data.value.numbers"
      :key="el.number"
      class="el"
    >
      <div
        class="number typo--title-big"
        v-html="madText(el.number)"
      />
      <div
        class="text typo--subtitle"
        v-html="el.text"
      />
    </div>
  </div>
</template>

<script>
import { madText } from '@/assets/js/utils';

export default {
  name: 'Numbers',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
    };
  },
};
</script>

<style lang="scss" scoped>
.numbers {
  display: grid;
  row-gap: var(--spacer-80);
  column-gap: var(--spacer-20_40);

  @include mq(s) {
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
  }
  .el {
    text-align: center;
  }
}
</style>>
