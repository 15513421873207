<template>
  <footer
    v-show="showFooter"
    class="page-footer"
  >
    <div
      class="footer-wrapper spacer--padding--x--20_40 spacer--padding--y--40"
    >
      <div class="top-row">
        <div
          v-for="(item, id) in footer.top"
          :key="'top-' + id"
          class="cell"
        >
          <div
            class="label typo--text-small--uppercase"
            v-html="item.label"
          />
          <div
            class="text typo--text-subtitle"
            v-html="item.text"
          />
        </div>
      </div>
      <div class="bot-row">
        <div
          v-for="(item, id) in footer.bottom"
          :key="'bot-' + id"
          class="text typo--text-small"
          v-html="item.text"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['options', 'currentPost', 'lang']),
    showFooter() {
      if (this.$route.meta.hideFooter) {
        return false;
      }
      if (!this.$route.meta.customView && !this.currentPost) {
        return false;
      }
      return true;
    },
    footer() {
      const { footer, 'footer-en': footerEn } = this.options;
      return this.lang === 'en' ? footerEn : footer;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.detectGtagEvents();
    });
  },
  methods: {
    detectGtagEvents() {
      const els = this.$el.querySelectorAll('[data-gtag-event]');
      els.forEach((el) => {
        el.addEventListener('click', () => {
          if (window.gtag) {
            window.gtag('event', el.dataset.gtagEvent);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-footer {
  background: var(--black);

  .footer-wrapper {
    display: grid;
    color: var(--white);
    row-gap: var(--spacer-40);
    @include mq(m) {
      row-gap: 280px;
    }

    .top-row {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: minmax(0, 1fr);
      gap: 30px;

      @include mq($until: m) {
        grid-auto-flow: row;
      }
      .cell {
        display: grid;
        grid-auto-rows: min-content;
        row-gap: 30px;
      }
    }
    .bot-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 0;
    }
    ::v-deep a {
      color: var(--white);
      text-decoration: none;
    }
  }
}
</style>
