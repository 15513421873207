<template>
  <main>
    <div class="empty" />
    <Marquee
      :data="marquee_prop"
      class="marquee404"
    />
    <div class="spacer">
      <router-link :to="lang === 'en' ? '/en/' : '/'">
        Go to Home Page
      </router-link>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import Marquee from '@/components/blocks/marquee';

export default {
  name: 'NotFound',
  components: {
    Marquee,
  },
  computed: {
    ...mapGetters(['lang']),
  },
  data() {
    return {
      marquee_prop: {
        value: {
          text: '404 - pAgE Not foUnD -',
        },
        settings: {
          bg_color: 'orange',
        },
      },
    };
  },
};
</script>
<style lang="scss" scoped>
main {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-columns: 100%;
  min-height: calc(100 * var(--vh));
}

.marquee404 {
  border: solid var(--black);
  border-width: var(--line) 0;
}

.spacer {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 20px;
  }
}
</style>
