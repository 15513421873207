<template>
  <div class="newsletter-social t-center">
    <div
      class="newsletter"
      :class="[data.settings.bg_color]"
    >
      <div
        class="newsletter-wrapper spacer--padding--x--20_40 spacer--padding--y--40_80"
      >
        <div
          class="title typo--title-big"
          v-html="madText(data.value.newsletter.title)"
        />
        <div
          class="subtitle typo--subtitle"
          v-html="data.value.newsletter.subtitle"
        />
        <div
          v-if="!success"
          class="email"
          @keyup.enter="submit"
        >
          <input
            ref="email"
            v-model="email"
            type="email"
            placeholder="La tua email..."
          >
          <div
            class="button"
            @click="submit"
          >
            <svg viewBox="0 0 100 100">
              <path
                d="M 34,6 L 90,50 L 34,94"
                class="arrow"
              />
            </svg>
          </div>
        </div>
        <div
          v-if="submitMsg"
          v-html="submitMsg"
        />
      </div>
    </div>
    <!-- <div class="line" /> -->
    <div
      class="social"
      :class="[data.settings.bg_color]"
    >
      <div
        class="social-wrapper spacer--padding--x--20_40 spacer--padding--y--40_80"
      >
        <div
          class="title typo--title-big"
          v-html="madText(data.value.social.title)"
        />
        <div class="links">
          <a
            v-for="(link, id) in data.value.social.links"
            :key="id"
            class="link"
            :href="link.url"
            target="_blank"
          >
            <inline-svg
              :src="require(`@/assets/svg/icons/${link.icon}.svg`)"
              :aria-label="`Link to ${link.icon}`"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { madText } from '@/assets/js/utils';

export default {
  name: 'NewsletterSocial',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
      email: '',
      success: false,
      avoid: false,
      submitMsg: '',
    };
  },
  computed: {
    ...mapGetters(['options', 'lang']),
  },
  methods: {
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
    },
    submit() {
      const emailIsValid = this.validateEmail(this.email) !== null;
      if (!this.avoid) {
        if (emailIsValid) {
          this.avoid = true;
          this.submitMsg = '';
          const request = {
            fn: 'mailchimp',
            params: {
              list_id: this.options.extra.mailchimp_list_id.value,
              email: this.email,
            },
          };
          this.$store
            .dispatch('sendRequest', request)
            .then((response) => {
              if (window.gtag) {
                window.gtag('event', 'form-newsletter');
              }

              if (response.data?.status === 'subscribed') {
                this.success = true;
                this.submitMsg = this.lang === 'en' ? 'Thank you!' : 'Grazie!';
              } else if (
                response.data?.status === 400
                && response.data.title?.toLowerCase() === 'member exists'
              ) {
                this.success = false;
                this.submitMsg = this.lang === 'en'
                  ? 'This email has already been subscribed.'
                  : 'Indirizzo email già registrato.';
              } else {
                this.success = false;
                this.submitMsg = this.lang === 'en'
                  ? 'Something went wrong. Double check if the email address is correct or try later.'
                  : 'Qualcosa è andato storto. Controlla di aver inserito correttamente l\'indirizzo email o riprova più tardi.';
              }
              this.avoid = false;
            })
            .catch(() => {
              this.success = false;
              this.avoid = false;
            });
        } else {
          this.submitMsg = this.lang === 'en'
            ? 'Please enter a valid email.'
            : 'Inserisci un indirizzo email valido.';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.newsletter-social {
  display: grid;
  @include mq(m) {
    grid-template-columns: 2fr 1fr;
  }
  gap: var(--line);
  background: var(--black);

  .newsletter,
  .social {
    display: grid;
    // justify-content: center;
    justify-items: center;

    &-wrapper {
      max-width: 640px;
      width: 100%;
    }
  }
  .newsletter {
    &-wrapper {
      display: grid;
      justify-items: center;
      row-gap: var(--spacer-20_40);
    }
    .subtitle {
      max-width: 400px;
    }
    .email {
      display: grid;
      grid-template-columns: 1fr min-content;
      align-items: center;
      justify-self: stretch;
      height: var(--spacer-40_80);
      background: var(--white);
      border: var(--line) solid var(--black);
      @include mq($until: s) {
        margin-top: var(--spacer-40);
      }

      input {
        width: 100%;
        height: 100%;
        padding-left: var(--spacer-20_40);
        text-overflow: ellipsis;
        border: none;

        &:focus-visible {
          outline: none;
        }
      }
      .button {
        display: flex;
        align-items: center;
        height: 100%;
        cursor: pointer;

        svg {
          padding: 0 var(--spacer-15);
          height: 20px;
          user-select: none;
          pointer-events: none;

          path.arrow {
            // d: path("M 34,6 L 90,50 L 34,94");
            stroke-width: 12px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: black;
            fill: none;
          }
        }
      }
    }
  }
  .social {
    &-wrapper {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      height: 100%;
    }
    .links {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      height: var(--spacer-40_80);
      margin-top: var(--spacer-40);

      .link {
        text-decoration: none;
        line-height: 0;
        transition: transform 0.2s ease-in-out;
        will-change: transform;

        @include mq($and: $hover) {
          &:hover {
            transform: rotate(-15deg);
          }
        }

        svg {
          height: 33px;
          @include mq(s) {
            height: 42px;
          }
        }
      }
    }
  }
}
</style>
>
