<template>
  <div>
    <section
      v-for="(block, index) in data"
      :id="sectionId(block)"
      :key="index"
      :class="['section', bgCol(block), `section--${parseBlockName(block.blockName)}`]"
    >
      <component
        :is="components.get(parseBlockName(block.blockName))"
        :data="block.blockName.includes('acf/') ? block.attrs.data : block"
      />
      <div class="line" />
    </section>
  </div>
</template>

<script>
import slugify from 'slugify';
import { parseBlockName } from '@/assets/js/utils';
import components from '@/assets/js/components';

export default {
  name: 'Gutenberg',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      parseBlockName,
      components,
    };
  },
  methods: {
    sectionId(block) {
      const label = block.attrs?.data?.settings?.custom_label;
      return (label && label !== '') ? slugify(label, {
        lower: true,
        strict: true,
      }) : false;
    },
    bgCol(block) {
      return block.attrs?.data?.settings?.bg_color || false;
    },
  },
};
</script>

<style>
.line {
  height: var(--line);
  background: var(--black);
}
</style>
