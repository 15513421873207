<template>
  <transition name="snackbar">
    <div
      v-if="active"
      :class="['snackbar', `snackbar-theme--${theme}`]"
    >
      <Spacer
        all="s"
        right="l"
      >
        <Richtext :data="{ innerHTML: content }" />
        <Icon
          name="close"
          size="xs"
          :fn="close"
          class="icon--close"
        />
      </Spacer>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/ui/icon';
import Richtext from '@/components/typo/richtext';

export default {
  name: 'Snackbar',
  components: {
    Icon,
    Richtext,
  },
  props: {
    timeout: {
      type: Number,
      default: 3500,
    },
  },
  computed: {
    ...mapGetters(['snackbar']),
    active() {
      return this.snackbar.active;
    },
    theme() {
      if (this.snackbar.theme) {
        return this.snackbar.theme;
      }
      return 'error';
    },
    content() {
      if (this.theme === 'error' && !this.snackbar.content) {
        return this.$labels.error_generic;
      }
      return this.snackbar.content;
    },
  },
  watch: {
    active() {
      if (this.active) {
        setTimeout(() => {
          this.$store.dispatch('closeSnackbar');
          this.$store.commit('SET_SNACKBAR', { active: false, content: null });
        }, this.timeout);
      }
    },
  },
  methods: {
    close() {
      this.$store.commit('SET_SNACKBAR', { active: false, content: null });
    },
  },
};
</script>

<style lang="scss">
.snackbar-enter-active,
.snackbar-leave-active {
  transition: all 0.3s ease;
}
.snackbar-enter,
.snackbar-leave-to {
  transform: translateY(200%);
}
.snackbar {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: var(--border-radius);

  z-index: 30;
  .flex {
    flex-wrap: nowrap;
    min-height: 30px;
  }
  &-theme {
    // &--default {
    // }
    &--error {
      background: var(--warning-color);
      color: var(--white);
      path {
        fill: var(--white);
      }
    }
    &--success {
      background: var(--primary-color);
      color: var(--black);
    }
  }
}
</style>
