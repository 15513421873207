<template>
  <nav
    class="page-menu"
    :class="[ bg, navUp ? 'nav__up' : '' ]"
  >
    <Flex
      align="center"
      justify="center"
    >
      <div
        v-for="(section, index) in data"
        :key="index"
        class="page-menu__item"
      >
        <router-link
          :to="`#${getComponentSlug(section, index)}`"
          :data-section-slug="getComponentSlug(section, index)"
          replace
          @click.native="scrollToCurrentSection($route.hash)"
        >
          {{ getSectionTitle(section) }}
        </router-link>
      </div>
    </Flex>
  </nav>
</template>

<script>
/*
  Per utilizzare il componente:
   - Installare pacchetti
    npm i slugify
    npm i gsap
    npm i body-scroll-lock
   - Aggiungere nei settings "show_in_page_menu" con campo ACF true/false e il campo "page_menu_label" text
   - Inserire il componente <PageMenu :key="$route.path" /> in App.vue sotto <AppHeader>
   - Se non ci sono le label, inserire a ogni section il proprio ID corrispondente in section settings
*/
import slugify from 'slugify';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import gsap, { ScrollToPlugin } from 'gsap/all';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'PageMenu',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      firstLoading: true,
      prevSection: null,
      prevPosition: 0,
      navUp: false,
    };
  },
  computed: {
    bg() {
      return this.$route.path.includes('servizi') ? 'bg--lightblue' : this.$route.path.includes('stanze') ? 'bg--rose' : '';
    },
  },
  watch: {
    // $route(to, from) {
    //   if (from.path === to.path && to.hash !== from.hash && to.hash) {
    //     this.prevSection = from.hash;
    //     this.scrollToCurrentSection(to.hash);
    //   }
    // },
    currentPost() {
      if (this.currentPost) {
        if (this.firstLoading === true && this.$route.hash) {
          this.scrollToCurrentSection(this.$route.hash);
        }
        this.firstLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.moveHeader);
    if (this.$route.hash) this.scrollToCurrentSection(this.$route.hash);
  },
  methods: {
    moveHeader() {
      const currentScrollPos = window.pageYOffset;
      if (this.prevScrollpos > currentScrollPos) {
        this.navUp = false;
      } else if (currentScrollPos) {
        this.navUp = true;
      }
      this.prevScrollpos = currentScrollPos;
    },
    getSectionTitle(section) {
      return section;
    },
    getComponentSlug(section, index) {
      const label = section;
      return label ? `${slugify(label, {
        lower: true,
        strict: true,
      })}` : `section-${index + 1}`;
    },
    scrollToCurrentSection(sectionID) {
      if (!document.querySelector(sectionID)) {
        return;
      }
      this.$store.commit('SET_ISSCROLLING', true);
      disableBodyScroll(this.$el);
      let offset = 0;
      let direction = 'down';
      if (document.querySelector(sectionID) && document.querySelector(sectionID).offsetTop <= this.prevPosition) {
        direction = 'up';
      }
      if (document.querySelector('header')) {
        offset += document.querySelector('header').offsetHeight;
      }
      if (document.querySelector('.page-menu') && direction === 'up') {
        offset += document.querySelector('.page-menu').offsetHeight;
      }
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo: {
          y: document.querySelector(sectionID),
          offsetY: offset,
        },
        duration: 0.4,
        ease: 'expo.out',
        onComplete: () => {
          this.$store.commit('SET_ISSCROLLING', false);
          clearAllBodyScrollLocks();
          this.prevPosition = window.scrollY;
        },
      });
    },
  },
};
</script>

<style lang="scss">

.page-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: var(--spacer-40);
  pointer-events: none;
  transition: transform;
  transition-duration: 0.4s;
  will-change: transform;
  z-index: 10;
  @include mq($until: m) {
    display: none;
  }

  .page-menu__item {
    background: var(--cream);
    outline: var(--line) solid var(--black);
    margin-top: var(--line);
    pointer-events: auto;
    @include mq($and: $hover) {
      @include border-bottom-hover();
    }

    a {
      display: block;
      text-decoration: none;
      padding: 9px 27px 13px;
    }
  }
  &.bg--lightblue {
    .router-link-exact-active {
      background: var(--lightblue);
    }
  }
  &.bg--rose {
    .router-link-exact-active {
      background: var(--rose);
    }
  }

//   top: 70px;
//   width: 100%;
//   z-index: 100;
//   @extend %sans;
//   @extend %typo--s;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
//   transition: transform 0.2s ease-in-out;
//   display: none;
//   @include mq(s){
//     display: block;
//   }
}

.nav__up{
  transform: scale(0.95);
}

</style>
