<template>
  <div class="content-gallery spacer--padding--y--40">
    <div class="content spacer--padding--x--20_40">
      <div
        class="title typo--title-big"
        v-html="madText(data.value.title)"
      />
      <div
        class="text typo--subtitle"
        v-html="data.value.content"
      />
      <div class="services typo--text-big">
        <ul>
          <li
            v-for="service in data.value.services"
            :key="service.term_id"
            v-html="service.name"
          />
        </ul>
      </div>
    </div>
    <Gallery
      v-if="data.value && data.value.gallery && data.value.gallery.length > 0"
      :data="data"
    />
  </div>
</template>

<script>
import Gallery from '@/components/media/gallery';
import { madText } from '@/assets/js/utils';

export default {
  name: 'ContentGallery',
  components: {
    Gallery,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-gallery {
  .content {
    display: grid;
    row-gap: var(--spacer-15);
    column-gap: var(--spacer-20_40);
    max-width: 1440px;
    margin: auto;

    @include mq(m) {
      grid-auto-flow: column;
      grid-auto-columns: minmax(min-content, 1fr);
    }

    .services ul {
      list-style-position: inside;
      list-style-type: '• \00a0';
      padding-left: 10px;
    }
  }
}
</style>>
