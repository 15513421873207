<template>
  <div
    class="sticker"
    :style="{
      '--x': data.pos.x,
      '--y': data.pos.y + 'px',
      '--rot': data.rot + 'deg',
    }"
    @mousedown="popSticker"
  >
    <img
      class="sticker-image"
      :src="data.sticker.url"
    >
  </div>
</template>

<script>
export default {
  name: 'Sticker',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    popSticker() {
      this.$el.style.display = 'none';
    },
  },
};
</script>

<style lang="scss">
.sticker {
  position: absolute;
  top: 0;
  left: 0;
  width: var(--sticker-size);
  height: var(--sticker-size);
  transform: translate(calc(var(--x, -1) * 80vw + 10vw - 50%), var(--y, -1000px)) rotate(var(--rot, 0deg));  cursor: pointer;
  pointer-events: auto;
  user-select: none;

  --sticker-size: 150px;
  @include mq(m) {
    --sticker-size: 200px;
  }
  @include mq(l) {
    --sticker-size: 270px;
  }

  .sticker-image {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
