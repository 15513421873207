var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"typo--menu t-center"},[_vm._l((_vm.navigation),function(item){return _c('div',{key:item.id,class:['nav-link', item.css]},[_c('Link',{staticClass:"spacer--padding--menu",attrs:{"data":{
        url: item.url,
        title: item.content,
        target: item.target,
      }},nativeOn:{"click":function($event){item.css.includes('cta-booking')
          ? _vm.detectGtagEvent()
          : undefined}}})],1)}),_c('LanguageSelector',{staticClass:"nav-link lang-sel"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }