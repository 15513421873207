<template>
  <div class="room">
    <ContentGallery
      v-if="data.value.room.gds_blocks[0].blockName === 'acf/content-gallery'"
      :data="data.value.room.gds_blocks[0].attrs.data"
    />
  </div>
</template>

<script>
import ContentGallery from '@/components/blocks/content-gallery';

export default {
  name: 'Room',
  components: {
    ContentGallery,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.room {

}
</style>>
