<template>
  <div
    ref="gallery"
    class="gallery"
  >
    <div
      v-for="(item, index) in repeatedGallery(data.value.gallery)"
      :key="index"
      :class="['item', item.width > item.height ? 'item-w' : 'item-h']"
    >
      <Figure
        :data="{
          value: {
            image: item,
          },
          settings: {},
        }"
        :lazyload="false"
        disable-ratio
        :theme="item.width > item.height ? 'gallery-w' : 'gallery-h'"
      />
    </div>
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';
import { repeatedGallery } from '@/assets/js/utils';

import Figure from '@/components/media/figure';

require('flickity-imagesloaded');

export default {
  name: 'Gallery',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      gallery: null,
      settings: {
        cellAlign: 'center',
        cellSelector: '.item',
        imagesLoaded: true,
        setGallerySize: true,
        prevNextButtons: true,
        pageDots: false,
        contain: false,
        wrapAround: true,
        freeScroll: true,
        freeScrollFriction: 0.05,
        percentPosition: false,
      },
    };
  },
  mounted() {
    if (this.$mq.isTouchDevice) {
      this.settings.freeScroll = false;
    }
    this.gallery = new Flickity(
      this.$refs.gallery,
      Object.assign(this.settings, this.extraSettings),
    );
    this.changeArrow();
  },
  beforeUnmount() {
    this.gallery.destroy();
  },
  methods: {
    repeatedGallery,
    changeArrow() {
      this.$refs.gallery.querySelectorAll('.arrow').forEach((el) => { el.setAttribute('d', 'M 66,6 L 10,50 L 66,94'); });
    },
  },
};
</script>

<style lang="scss">
  .gallery {
    overflow: hidden;
    padding-top: var(--spacer-40_80);
    padding-bottom: var(--spacer-40_80);
    @include mq(s) {
      padding-top: var(--spacer-120);
      padding-bottom: var(--spacer-120);
    }

    .flickity-viewport {
      overflow: visible;
    }

    .flickity-slider {
      display: flex;
      align-items: center;
    }

    .item {
      overflow: hidden;
      margin-right: 40px;
      width: 100%;

      --max: 400px;
      @include mq(s) {
        --max: 600px;
      }

      &-w {
        width: var(--max);
      }

      &-h {
        width: auto;
        height: var(--max);
      }
    }

    .flickity-prev-next-button {
      --size: 40px;
      @include mq(s) {
        --size: 54px;
      }
      width: var(--size);
      height: var(--size);
      border: var(--line) solid var(--black);
      &.previous {
        left: var(--spacer-20_40);
      }
      &.next {
        right: var(--spacer-20_40);
      }
      &.flickity-button {
        background: var(--white);
        &:hover,
        &:active {
          background: var(--white);
          opacity: 1;
        }
        .flickity-button-icon path.arrow {
          stroke-width: 12px;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke: black;
          fill: none;
        }
      }
    }
  }
</style>
