<template>
  <div id="app">
    <AppHeader />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <AppFooter />
    <div class="stickers">
      <Sticker
        v-for="(sticker, index) in stickers"
        :key="index"
        :data="sticker"
      />
    </div>

    <Modal />
    <Snackbar />
    <Loader />
    <Cookie v-if="options && options.cookies" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import lazyload from '@/mixins/lazyload';
import { spawnStickers } from '@/assets/js/utils';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import Sticker from '@/components/media/sticker';

import Modal from '@/components/ui/modal';
import Snackbar from '@/components/ui/snackbar';
import Loader from '@/components/ui/loader';
import Cookie from '@/components/ui/cookie';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    Sticker,
    Modal,
    Cookie,
    Loader,
    Snackbar,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost', 'options']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener(
      'resize',
      debounce(() => {
        this.$bus.$emit('windowResized');
        Vue.set(Vue.prototype, '$mq', this.$mq.setMq());
        this.$mq.vh();
      }, 400),
    );

    this.stickers = this.spawnStickers(this.options?.stickers);
  },
  data() {
    return {
      stickers: null,
    };
  },
  methods: {
    enter(el, done) {
      this.$store.commit('SET_TRANSITION', false);
      done();
    },
    leave(el, done) {
      this.$store.commit('SET_TRANSITION', true);
      done();
    },
    spawnStickers,
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.app-loaded {
  position: relative;

  .stickers {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    // width: 100%;
    // height: 100%;
    overflow: hidden;
    pointer-events: none;
    @include mq($until: m) {
      top: 70px;
    }
  }

  #loader {
    display: none;
  }
  .section:not(.section--marquee):not(.section--gallery):not(.section--room):not(.section--content-gallery) > *:first-child,
  .footer-wrapper {
    max-width: 1440px;
    margin: auto;
  }
  .cream {
    background: var(--cream);
  }
  .white {
    background: var(--white);
  }
  main {
    @include mq($until: m) {
      padding-top: 70px;
    }
  }
}
</style>
