<template>
  <header
    v-show="!$route.meta.hideHeader"
    class="header spacer--padding--x-m--20_40 spacer--padding--y-m--40"
  >
    <div class="menu">
      <router-link
        :to="lang === 'en' ? '/en/' : '/'"
        class="logo"
      >
        <img
          :src="require('@/assets/svg/logo.svg')"
        >
      </router-link>

      <Menu
        class="dropdown"
        :class="{open: menuIsOpen}"
      />
      <div
        class="button"
        @click="toggleMenu"
      >
        <img
          :src="require(`@/assets/svg/icons/${menuIsOpen?'close':'hamburger'}.svg`)"
        >
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Menu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['lang']),
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.menuIsOpen = false;
      }
    },
  },
  methods: {
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @include mq(m) {
    pointer-events: none;
  }

  .menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    box-sizing: content-box;
    @include mq($until: m) {
      width: 100%;
      height: 70px;
      background: var(--cream);
      border-bottom: var(--line) solid var(--black);
    }

    ::v-deep a {
      pointer-events: auto;
    }
    .logo {
      width: auto;
      height: 100%;
      @include mq(m) {
        background: var(--cream);
        border: var(--line) solid var(--black);
        transform: rotate(-3deg);
        transition: transform 0.1s ease-in-out;
      }

      img {
        display: block;
        width: auto;
        height: 100%;
        padding: 14px;
        @include mq(m) {
          width: 240px;
          padding: 20px;
          padding-right: 40px;
        }
      }
      @include mq($and: $hover) {
        &:hover {
          @include mq(m) {
            transform: rotate(-6deg);
          }
        }
      }
    }
    .dropdown {
      @include mq($until: m) {
        position: absolute;
        top: calc(100% - var(--line));
        transform: translateY(-100%);
        transition: 0.3s ease-in-out;
        transition-property: transform, visibility;
        will-change: transform;
        z-index: -10;
        visibility: hidden;

        &.open {
          transform: translateY(0);
          visibility: visible;
        }
      }
    }
    .button {
      @include mq(m) {
        display: none;
      }
      align-self: center;
      cursor: pointer;
      img{
        display: block;
      }
    }
  }
}
</style>
