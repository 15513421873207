<template>
  <div class="event spacer--padding--y--40">
    <div
      class="main-title typo--title-big spacer--padding--x--20_40"
      v-html="madText(data.value.title)"
    />
    <div class="events spacer--padding--t--20_40 spacer--padding--l--20_40">
      <EventThumb
        v-for="item in data.value.items"
        :key="'event-'+item.event.id"
        :data="item"
        :class="[data.value.items.length === 1 ? 'single' : '']"
      />
    </div>
  </div>
</template>

<script>
import { madText } from '@/assets/js/utils';
import EventThumb from '@/components/thumb/event-thumb';

export default {
  name: 'Event',
  components: {
    EventThumb,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
    };
  },
};
</script>

<style lang="scss" scoped>
.event {
  .main-title {
    text-align: center;
  }
  .events {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(calc(280px + var(--spacer-20_40)), 1fr);
    // column-gap: var(--spacer-20_40);
    margin: auto;
    overflow: auto;
    @extend %hide-scrollbar;
    scroll-snap-type: x mandatory;
    scroll-padding-left: var(--spacer-20_40);

    @include mq(s) {
      max-width: calc(2 * var(--spacer-20_40) + 1000px);
      padding-top: var(--spacer-20_40);
      padding-bottom: 85px;
      column-gap: var(--spacer-20_40);
    }
  }
}

%hide-scrollbar {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    display: none;
  }
}
</style>>
