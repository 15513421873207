<template>
  <nav class="typo--menu t-center">
    <div
      v-for="item in navigation"
      :key="item.id"
      :class="['nav-link', item.css]"
    >
      <Link
        :data="{
          url: item.url,
          title: item.content,
          target: item.target,
        }"
        class="spacer--padding--menu"
        @click.native="
          item.css.includes('cta-booking')
            ? detectGtagEvent()
            : undefined
        "
      />
    </div>
    <LanguageSelector class="nav-link lang-sel" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import Link from '@/components/typo/link';
import LanguageSelector from '@/components/ui/language-selector';

export default {
  name: 'Menu',
  components: {
    Link,
    LanguageSelector,
  },
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
  methods: {
    detectGtagEvent() {
      if (window.gtag) {
        window.gtag('event', 'cta-booking');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  flex-flow: column;
  width: 100%;
  @include mq(m) {
    width: auto;
    flex-flow: row wrap;
  }

  .nav-link {
    background: var(--cream);
    outline: var(--line) solid var(--black);
    margin-top: var(--line);
    @include mq(m) {
      margin-left: var(--line);
    }

    .link {
      display: block;
      text-decoration: none;

      &.spacer--padding--menu {
        padding: 9px 27px 13px;
      }
      @include mq($and: $hover) {
        @include border-bottom-hover();
      }
    }
    &.bg {
      &--lightblue {
        &--on,
        // .link:hover,
        .router-link-active {
          background: var(--lightblue);
        }
      }
      &--rose {
        &--on,
        // .link:hover,
        .router-link-active {
          background: var(--rose);
        }
      }
      &--orange {
        &--on,
        // .link:hover,
        .router-link-active {
          background: var(--orange);
        }
      }
    }
  }
  .lang-sel {
    display: flex;
    @include mq($until: m) {
      order: -1;
    }
  }
}
</style>
