import Title from '@/components/typo/title';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Figure from '@/components/media/figure';
import VideoPlayer from '@/components/media/video';
import Gallery from '@/components/media/gallery';
import Archive from '@/components/blocks/archive';
import Posts from '@/components/blocks/posts';
import Marquee from '@/components/blocks/marquee';
import Hero from '@/components/blocks/hero';
import Numbers from '@/components/blocks/numbers';
import ContentGallery from '@/components/blocks/content-gallery';
import Event from '@/components/blocks/event';
import MediaContent from '@/components/blocks/media-content';
import NewsletterSocial from '@/components/blocks/newsletter-social';
import Room from '@/components/blocks/room';

const components = new Map();

components.set('title', Title);
components.set('paragraph', Richtext);
components.set('link', Link);
components.set('image', Figure);
components.set('video', VideoPlayer);
components.set('gallery', Gallery);
components.set('archive', Archive);
components.set('post', Posts);
components.set('marquee', Marquee);
components.set('hero', Hero);
components.set('numbers', Numbers);
components.set('content-gallery', ContentGallery);
components.set('event', Event);
components.set('media-content', MediaContent);
components.set('newsletter-social', NewsletterSocial);
components.set('room', Room);

export default components;
