<template>
  <div class="hero spacer--padding--x--20_40 spacer--padding--y--40">
    <Grid
      row-gap="l"
      column-gap="xl"
    >
      <div
        v-if="data.settings.title"
        class="title typo--title-big col-3"
        v-html="madText(data.value.title)"
      />
      <Figure
        :data="{
          value: {
            image: data.value.image,
          },
          settings: {},
        }"
        :lazyload="false"
        :class="['img', data.settings.title ? 'col-3' : 'col-6']"
        theme="hero"
        disable-ratio
      />
      <div
        v-if="data.settings.text"
        class="subtitle typo--subtitle col-2"
        v-html="data.value.subtitle"
      />
      <div
        v-if="data.settings.text"
        class="content typo--text-big col-4 text--columns"
        v-html="data.value.content"
      />
    </Grid>
  </div>
</template>

<script>
import { madText } from '@/assets/js/utils';
import Figure from '@/components/media/figure';

export default {
  name: 'Hero',
  components: {
    Figure,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      madText,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  .grid {
    --columns: 1;
    @include mq(s) {
      --columns: 6;
    }
    & > * {
      @include mq($until: s) {
        -ms-grid-column: auto;
        grid-column-start: auto;
        -ms-grid-column-span: var(--columns);
        grid-column-end: span var(--columns);
      }
    }
  }
  .subtitle {
    @include mq(s) {
      grid-column: span var(--columns);
    }
    @include mq(m) {
      grid-column: span 2;
    }
  }
  .content {
    @include mq(s) {
      grid-column: span var(--columns);
    }
    @include mq(m) {
      grid-column: span 4;
    }
  }
  .text--columns {
    column-gap: var(--column-gap-xl);
    @include mq(s) {
      columns: 2;
    }
    @include mq(m) {
      columns: 1;
    }
    @include mq(l) {
      columns: 2;
    }
  }
}
</style>>
