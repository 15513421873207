<template>
  <div
    class="marquee typo--title-big"
    :class="[data.settings.bg_color]"
  >
    <MarqueeText :repeat="6">
      <div v-html="madText(data.value.text.trim())+'&puncsp;'" />
    </MarqueeText>
  </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component';
import { madText } from '@/assets/js/utils';

export default {
  name: 'Marquee',
  components: {
    MarqueeText,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    madText,
  },
};
</script>

<style lang="scss" scoped>
.marquee {
  display: flex;
  align-items: center;
  height: 78px;
  @include mq(s) {
    height: 240px;
    font-size: 100px;
  }

  &.orange {
    background-color: var(--orange);
  }
  &.lightblue {
    background-color: var(--lightblue);
  }
  &.rose {
    background-color: var(--rose);
  }

  .marquee-text-text {
    // padding-right: 0.18em;
    will-change: transform;
  }
}
</style>>
